<template>
  <div class="favorite-game">
    <div class="favorite-game__list container-custom">
      <SectionTitle title="Danh Mục Cá Cược" />
      <GameItems />
    </div>
  </div>
</template>
<script setup lang="ts">
import SectionTitle from '~/components/common/section-title.vue'
const GameItems = defineAsyncComponent(() => import('~/components/desktop/pages/home/game-center/list.vue'))
</script>
<style scoped lang="scss" src="assets/scss/components/desktop/pages/home/game-center/index.scss"></style>
