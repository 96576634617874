<template>
  <div class="home">
    <Banner />
    <GameCenter />
    <div class="container-custom">
      <SectionGame />
      <SectionTopBet />
    </div>
  </div>
  <SeoContent />
</template>

<script setup lang="ts">
import Banner from '~/components/desktop/pages/home/banner/index.vue'
import GameCenter from '~/components/desktop/pages/home/game-center/index.vue'
import SectionTopBet from '~/components/desktop/pages/home/top-bet/index.vue'
import SectionGame from '~/components/desktop/pages/home/section-game/index.vue'
import SeoContent from '~~/components/common/seo-content.vue'
import { useJackpot } from '~~/composables/game/useJackpot'

const { fetchJackpot } = useJackpot()

useAsyncData(async () => {
  await fetchJackpot()
})
</script>

<style lang="scss" scoped src="assets/scss/components/desktop/pages/home/index.scss"></style>
