<template>
  <div class="top-bet">
    <SectionTitle :title="TITLE_SECTION.TOP_PLAY" />
    <div class="top-bet__container">
      <template v-for="(item, index) in gameList.slice(0, 6)" :key="index">
        <CommonGamesItemGame class="item-game" :item="item" show-coin />
      </template>
    </div>
  </div>
</template>
<script setup lang="ts">
import queryString from 'query-string'
import CommonGamesItemGame from '~/components/common/game-item.vue'
import { useGame } from '~~/composables/game/useGame'
import { TITLE_SECTION } from '~/constants/home'
import { LOBBY_GAME_URLS } from '~/config/page-url'

const CommonBaseImg = defineAsyncComponent(() => import('~/components/common/base-img.vue'))
const SectionTitle = defineAsyncComponent(() => import('@/components/common/section-title.vue'))
const { fetchGames } = useGame()
const gameList = ref([])
const fetchTopBetGames = async () => {
  try {
    const queryUrl = queryString.stringify({
      path_alias: 'all',
      provider_code: '',
      sort_by: 'TOP_USER_BET',
      limit: 6,
      page: 1
    })
    const { data: response } = await fetchGames(queryUrl)
    if (response.status === 'OK') {
      gameList.value = response.data?.items
    }
  } catch (error) {
    console.error('fetchTopBetGames', error)
  }
}
useAsyncData(async () => {
  await fetchTopBetGames()
})
</script>
<style lang="scss" scoped src="assets/scss/components/desktop/pages/home/top-bet/index.scss"></style>
