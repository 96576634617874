<template>
  <nuxt-link class="section-game-bai">
    <SectionTitle icon-src="/assets/images/home/icon-game-bai.svg" title="Game bài" />
    <div class="line" />
    <div class="grid">
      <nuxt-link v-for="(image, index) in images" :key="index" :class="image.className" :to="image.url" class="item">
        <BaseImg :alt="image.alt" :src="homePath + image.src" />
      </nuxt-link>
    </div>
  </nuxt-link>
</template>
<script setup lang="ts">
import SectionTitle from '~/components/common/section-title.vue'
import { LOBBY_GAME_URLS } from '~/config/page-url'

interface ImageSource {
  alt: string
  src: string
  className?: string
  url: string
}
const homePath = '/assets/images/home/'
const images = ref<ImageSource[]>([
  {
    alt: 'Ncc hug iwin',
    src: 'icon-iwin-mb.png',
    className: 'img',
    url: LOBBY_GAME_URLS.GAME_BAI + '?provider=iwin&sort='
  },
  {
    alt: 'Ncc hug go88',
    src: 'icon-go88-mb.png',
    className: 'img',
    url: LOBBY_GAME_URLS.GAME_BAI + '?provider=go&sort='
  },
  {
    alt: 'Ncc hug rik',
    src: 'icon-rik-mb.png',
    className: 'img',
    url: LOBBY_GAME_URLS.GAME_BAI + '?provider=rik&sort='
  },
  {
    alt: 'Ncc hug b52',
    src: 'icon-b52-mb.png',
    className: 'img',
    url: LOBBY_GAME_URLS.GAME_BAI + '?provider=b52&sort='
  },
  {
    alt: 'Ncc hug techplay',
    src: 'icon-techplay-mb.png',
    className: 'ncc-hug-techplay',
    url: LOBBY_GAME_URLS.GAME_BAI + '?provider=vingame&sort='
  }
])
</script>

<style scoped lang="scss" src="assets/scss/components/mobile/pages/home/section-game-bai/index.scss"></style>
