<template>
  <div class="brand-info">
    <div v-if="$device.isMobileOrTablet" class="list-reserve">
      <a
        href="//www.dmca.com/Protection/Status.aspx?ID=f362511c-0d21-4b76-af37-f633d9a377ba"
        title="DMCA.com Protection Status"
        class="dmca-badge"
        target="_blank"
      >
        <CommonBaseImg :src="`${PATH_FOOTER_URL}dmca.svg`" alt="DMCA.com Protection Status" />
      </a>
      <CommonBaseImg :src="`${PATH_FOOTER_URL}copyright-logo.webp`" class="reserve-image" alt="copy right" />
    </div>
    <div v-if="$device.isMobileOrTablet" class="copy-right">
      {{ FOOTER_CONTENT.COPY_RIGHT }}
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import CommonBaseImg from '~/components/common/base-img'
import { PATH_FOOTER_URL, PATH_IMAGES } from '~/constants/path'
import { footerContentData } from '@/constants/footer'
import { useAppStore } from '~/store/app'

const FOOTER_CONTENT = footerContentData()
const { $device } = useNuxtApp()
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { seoData } = storeToRefs(store)
const route = useRoute()
const dataSeo = computed(() => {
  return seoData.value.find((item) => item.alias === '/')
})
</script>

<style scoped lang="scss" src="assets/scss/components/common/brand-info.scss" />
