<template>
  <section id="type-bet" class="section-type-bet">
    <SectionTitle title="Danh mục" />
    <div class="type-bet__list">
      <nuxt-link
        v-for="(item, index) in TYPE_BET_MB"
        :id="item?.name"
        :key="index"
        class="type-bet__item"
        :to="item.url"
        @click="handleClickTypeBet(item)"
      >
        <div class="type-bet__item--image">
          <CommonBaseImg
            :id="`${item?.name}-image`"
            :src="item?.image"
            :alt="item?.name"
            :lazy="!isBotChromeLighthouse"
          />
        </div>
        <div class="type-bet__item--name">
          <div v-if="item.jackpot && sumJackpot[item.jackpot] > 0" class="jackpot">
            <AnimateNumber :number="sumJackpot[item.jackpot]" />
          </div>
          {{ item?.title }}
        </div>
      </nuxt-link>
    </div>
  </section>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAppStore } from '~/store/app'
import CommonBaseImg from '~~/components/common/base-img.vue'
import { TYPE_BET_MB } from '~/constants/menu'
import AnimateNumber from '~/components/common/animated-number.vue'
import SectionTitle from '~/components/common/section-title.vue'

const { isBotChromeLighthouse } = useAppStore()
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { sumJackpot } = storeToRefs(store)

const router = useRouter()
const loadPage = ref(false)
setTimeout(() => {
  loadPage.value = true
}, 500)
const handleClickTypeBet = (item) => {
  router.push(item?.url)
}
</script>
<style scoped lang="scss" src="assets/scss/components/mobile/pages/home/section-type-bet/index.scss"></style>
