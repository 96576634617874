<template>
  <div class="home">
    <MobilePagesHomeSectionBanner />
    <MobilePagesHomeSectionTypeBet />
    <div class="home__container">
      <LazyHydrationWrapper when-visible @on-loading-game="loadingGame">
        <MobilePagesHomeSectionQuaySo />
      </LazyHydrationWrapper>
      <SectionGameBai />
      <LazyHydrationWrapper when-visible @on-loading-game="loadingGame">
        <MobilePagesHomeSectionTopPlay />
      </LazyHydrationWrapper>
      <LazyHydrationWrapper when-visible>
        <SectionIntroduce />
      </LazyHydrationWrapper>
      <LazyHydrationWrapper when-visible>
        <SectionContact />
      </LazyHydrationWrapper>
      <LazyHydrationWrapper when-visible>
        <SeoContent />
        <CommonBrandInfo />
      </LazyHydrationWrapper>
      <!-- <MobilePagesHomeModalBottom
        v-if="showPopup && !popupBottom && !currentUser && !isBotChromeLighthouse"
        @close="hidePopup"
      /> -->
    </div>
  </div>
</template>

<script setup lang="ts">
import { LazyHydrationWrapper } from 'vue3-lazy-hydration'
import SectionIntroduce from './section-introduce/index.vue'
import SectionContact from './section-contact/index.vue'
import MobilePagesHomeSectionBanner from './section-banner/index.vue'
import MobilePagesHomeSectionTypeBet from './section-type-bet/index.vue'
import MobilePagesHomeSectionQuaySo from './section-quay-so/index.vue'
import MobilePagesHomeSectionTopPlay from './section-top-play/index.vue'
import MobilePagesHomeModalBottom from './modal-bottom/index.vue'
import SectionGameBai from '~/components/mobile/pages/home/section-game-bai/index.vue'
import { useAppStore } from '~/store/app'
import CommonBrandInfo from '~/components/common/brand-info/index.vue'
import SeoContent from '~~/components/common/seo-content.vue'

const { $pinia } = useNuxtApp()
const { $state } = useAppStore($pinia)
const isLoading = ref(true)
const { isBotChromeLighthouse } = useAppStore()
onMounted(() => {
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 500) // 1000 milliseconds = 1 second
  window.addEventListener('scroll', handleScroll)
})
const showPopup = ref(false)
const popupBottom = useCookie<boolean>('setCheckLogin', {
  path: '/',
  maxAge: 60 * 60 * 24
})
if (!popupBottom.value) {
  popupBottom.value = false
}
const handleScroll = () => {
  if (window.scrollY > 50) {
    if (popupBottom && popupBottom.value) {
      return
    }
    showPopup.value = true
  }
}
const hidePopup = () => {
  popupBottom.value = true
}

const loadingGame = (loading) => {
  setTimeout(() => {
    isLoading.value = loading
  }, 500)
}

const currentUser = computed(() => {
  return $state.currentUser
})

setTimeout(() => {
  popupBottom.value = true
}, 10000)
</script>
<style scoped lang="scss" src="assets/scss/components/mobile/pages/home/index.scss"></style>
