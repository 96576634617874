<template>
  <div class="section-game-bai">
    <div class="section-game-bai__container">
      <SectionTitle :icon-src="`${homePath}icon-game-bai.svg`" title="Game Bài" description="Cược to thắng lớn" />
      <div class="line" />
      <div class="grid">
        <nuxt-link v-for="(image, index) in images" :key="index" :class="image.className" :to="image.url" class="item">
          <BaseImg :alt="image.alt" :src="homePath + image.src" />
        </nuxt-link>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import SectionTitle from '~/components/common/section-title.vue'
import { LOBBY_GAME_URLS } from '~/config/page-url'
import BaseImg from '~/components/common/base-img.vue'

export interface ImageSource {
  alt: string
  src: string
  className?: string
  url: string
}
const homePath = '/assets/images/home/'
const images = ref<ImageSource[]>([
  {
    alt: 'Ncc hug iwin',
    src: 'icon-iwin.png',
    className: 'img',
    url: LOBBY_GAME_URLS.GAME_BAI + '?provider=iwin&sort='
  },
  { alt: 'Ncc hug go88', src: 'icon-go88.png', className: 'img', url: LOBBY_GAME_URLS.GAME_BAI + '?provider=go&sort=' },
  { alt: 'Ncc hug rik', src: 'icon-rik.png', className: 'img', url: LOBBY_GAME_URLS.GAME_BAI + '?provider=rik&sort=' },
  { alt: 'Ncc hug b52', src: 'icon-b52.png', className: 'img', url: LOBBY_GAME_URLS.GAME_BAI + '?provider=b52&sort=' },
  {
    alt: 'Ncc hug techplay',
    src: 'icon-techplay.png',
    className: 'ncc-hug-techplay',
    url: LOBBY_GAME_URLS.GAME_BAI + '?provider=vingame&sort='
  }
])
const CommonBaseImg = defineAsyncComponent(() => import('~/components/common/base-img.vue'))
</script>
<style lang="scss" scoped src="assets/scss/components/desktop/pages/home/section-game-bai/index.scss"></style>
